<template>
  <footer
    absolute
    bottom
  >
    <FooterNewsletter />
    <FooterMain />
    <!-- <v-btn icon fixed class="success wpp-position" x-large>
        <v-icon color="white">fab fa-whatsapp</v-icon>
    </v-btn>
    <v-btn href="#header" icon fixed class="primary up-position" x-large>
        <v-icon color="white" small>fas fa-chevron-up</v-icon>
    </v-btn> -->
  </footer>
</template>

<script>
export default {
  components: {
    FooterMain: () => import('./FooterMain'),
    FooterNewsletter: () => import('./FooterNewsletter'),
  },
}
</script>

<style>
.wpp-position {
  position: fixed;
  right: 1%;
  top: 90%;
}
.up-position {
  position: fixed;
  right: 1%;
  top: 80%;
}
</style>
